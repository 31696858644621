html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #e3e3e3;
}

input[name="Password"]:-webkit-autofill,
input[name="Password"]:-webkit-autofill:hover,
input[name="Password"]:-webkit-autofill:focus,
input[name="Password"]:-webkit-autofill:first-line,
input[name="Password"]:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[name="Email"]:-webkit-autofill,
input[name="Email"]:-webkit-autofill:hover,
input[name="Email"]:-webkit-autofill:focus,
input[name="Email"]:-webkit-autofill:first-line,
input[name="Email"]:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

div::-webkit-scrollbar {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: #fff;
  width: 5px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c6defd;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 0;
  border-radius: 10px;
}

.video-container .scan-region-highlight {
  border-radius: 30px;
  outline: rgba(0, 0, 0, 0.25) solid 50vmax;
}

.video-container .scan-region-highlight-svg {
  display: none;
}

.video-container .code-outline-highlight {
  stroke: #cc1d15 !important;
  stroke-width: 5 !important;
}
