.start {
  background-color: #cc1d15;
  color: #fff;
  border-radius: 2px;
  width: 30px;
  margin: auto;
  outline: 1px solid #cc1d15;
}
.end {
  background-color: #cc1d15;
  color: #fff;
  border-radius: 2px;
  width: 30px;
  margin: auto;
  outline: 1px solid #cc1d15;
}

/* .between {
  background-color: #F9E8E7;
} */

.muted {
  color: #F9E8E7;
  background: linear-gradient(90deg, #fff 50%, #fff 50%),

}

table {
  border-collapse: collapse;
}
